import { formatDateReadable } from '../Services/helpers';
import { v4 as uuidv4 } from 'uuid';
import { AUTH_STATES } from '../redux/auth/auth.types';

// Convert 24-hour time to 12-hour time
export const format24HourTime = time => {
  var hour = parseInt(time.substr(0, 2));
  var minute = time.substr(3, 2);
  var ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12;
  minute = minute > 10 ? minute : minute;
  var strTime = hour + ':' + minute + ' ' + ampm;
  return strTime;
};

// Encode to base64
export const base64Encode = data => {
  data = JSON.stringify(data);
  return btoa(data);
};

// Decode from base64
export const base64Decode = data => {
  data = atob(data);
  return JSON.parse(data);
};

// Set cookie
/**
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {int} days - The number of days until the cookie expires
 */
export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name +
    '=' +
    (value || '') +
    expires +
    '; path=/; domain=.' +
    process.env.REACT_APP_DOMAIN_NAME;
};

// Get cookie
export const getCookie = name => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Delete cookie
export const deleteCookie = name => {
  setCookie(name, '', -1);
  return;
};

// Get a parameter from the URL
export const getSearchParam = parameterName => {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split('&');
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

// Get hash
export const getHashParam = () => {
  var hash = window.location.hash.split('#')[1];
  if (!hash) return null;
  // Remove query string if any
  if (hash.includes('&')) {
    hash = hash.split('&')[0];
  }
  return hash;
};

// Get user state from selectot
export const getUserState = state => {
  const data = {
    isLoggedIn: state.state === AUTH_STATES.LOGIN && !state.error && !state.loading,
    isLoggedOut: state.state === AUTH_STATES.LOGOUT && !state.error && !state.loading,
    isLoggingIn: state.state === AUTH_STATES.LOGIN && !state.error && state.loading,
    isLoggingOut: state.state === AUTH_STATES.LOGOUT && !state.error && state.loading,
    isLoggingInError: state.state === AUTH_STATES.LOGIN && state.error && !state.loading,
    isLoggingOutError:
      state.state === AUTH_STATES.LOGOUT && state.error && !state.loading,
    user: state.user,
    error: state.error
  };
  return data;
};

// Get youtube thumbnail from url
export const getYoutubeThumbnail = url => {
  const videoId = url.split('/').pop();
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export const isImage = url => {
  return url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const getErrorMessage = err => {
  const defaultMessage = 'An error occurred. Please try again later, or contact support.';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.errors) {
        return Object.values(err.response.data.errors)[0][0];
      } else {
        return err.response.data.message;
      }
    } else {
      return err.response.message || err.response.statusText || defaultMessage;
    }
  }
  return defaultMessage;
};

// Generate UUID

export const generateUUID = () => {
  return uuidv4();
};

export const isListingPropertyExtra = propertibleType => {
  return propertibleType.endsWith('ListingCategoryPropertyExtra');
};

export const exportCSV = (data, filename) => {
  const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  //IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, filename);
  } else {
    //In FF link must be added to DOM to be clicked
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getDaytime = () => {
  const hour = new Date().getHours();
  if (hour >= 0 && hour < 12) {
    return 'morning';
  } else if (hour >= 12 && hour < 17) {
    return 'afternoon';
  } else {
    return 'evening';
  }
};

export const getDateString = date => {
  const today = new Date();
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  const dateString = new Date(date).toLocaleDateString();
  if (dateString === today.toLocaleDateString()) {
    return 'Today';
  } else if (dateString === yesterday.toLocaleDateString()) {
    return 'Yesterday';
  } else {
    return formatDateReadable(dateString);
  }
};

// Get time from an ISO string (e.g. 2020-01-01T00:00:00.000Z returns '12:00 AM')
export const getTimeFromISOString = date => {
  const dateString = new Date(date).toLocaleTimeString();
  return dateString.substring(0, dateString.lastIndexOf(':'));
};

/**
 * @param {imageSrc} url
 * @param {x} number
 * @param {y} number
 * @param {width} number
 * @param {height} number
 * @return {dataUrl} base64
 * */
export const getCroppedImage = (imageSrc, x, y, width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.src = imageSrc;
  ctx.drawImage(img, x, y, width, height, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg');
};

export const blobToFile = (blob, fileName) => {
  var file = new File([blob], fileName, { type: 'image/png' });
  return file;
};

// Return true if input string can be converted to a Date object
export const isDate = date => {
  try {
    new Date(date);
    return true;
  } catch (e) {
    return false;
  }
};

// Return ordinal e.g. 1st for 1, 2nd for 2, etc.
export const ordinal = n => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

// Return number of days between two dates
export const datetimeDifference = (date1, date2) => {
  const difference = date2.getTime() - date1.getTime();
  return parseInt(difference / (1000 * 60 * 60 * 24));
};

export const timeformatfrom24to12hrs = time => {
  const time24 = time;
  const date = new Date(`01/01/2000 ${time24}`);
  const time12 = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return time12;
};
